import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <script type="application/ld+json">
        {`{
          "@context": "http://schema.org",
          "@type": "WebPage",
          "name": "Страница не найдена",
          "description": "Извините, запрошенная страница не найдена.",
          "url": "https://www.krypton.ru/404",
          "breadcrumb": {
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "name": "Главная",
                "item": "https://www.krypton.ru"
              },
              {
                "@type": "ListItem",
                "name": "404",
                "item": "https://www.krypton.ru/404"
              }
            ]
          }
        }`}
      </script>
    </Helmet>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)
/**
 * Страница 404
 * @module src/page/404
 */
export default NotFoundPage
